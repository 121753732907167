import { configureStore } from '@reduxjs/toolkit'
import SidebarSlice from './Slices/SidebarSlice'
import SspSlice from './Slices/SspSlice'
import ModelsSlice from './Slices/ModelsSlice.js'

export const store = configureStore({
  reducer: {
    SidebarSlice,
    SspSlice,
    ModelsSlice
  },
})