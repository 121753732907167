import React, { Component, Suspense, useState } from 'react'
import { BrowserRouter, HashRouter, Route, Router, RouterProvider, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const GlobalSettingsPage = React.lazy(() => import('./views/pages/settings/GlobalSettingsPage'))


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: localStorage.getItem('AUTH')
    };
  }
  render() {
    return (
      <HashRouter >
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/login" name="Login Page" element={<Login setState={() => {this.setState({auth: true}); localStorage.setItem('AUTH', 'true')}}/>} />
            <Route path="/register" name="Register Page" element={<Register />} />
            <Route path="/404" name="Page 404" element={<Page404 />} />
            <Route path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={this.state.auth ? <DefaultLayout /> : <Login setState={() => {this.setState({auth: true}); localStorage.setItem('AUTH', 'true')}}/>} />
            {/* <Route path="/globalSettings" name="Global Settings Page" element={<GlobalSettingsPage/>}/> */}
            
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
