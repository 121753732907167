import axios from "axios";
export const baseUrl = `${process.env.REACT_APP_HOSTNAME}`;

axios.defaults.baseURL = baseUrl;

const ax = axios.create();


ax.interceptors.request.use(
    async (config) => {
            config.headers = {
                ContentType: 'application/json'
            }
        return config;
    },
);

export default ax;