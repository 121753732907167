import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ax from 'src/scss/utils/axios';


const initialState = {
    sidebarShow: true,
    projectInfo : null
};



    export const getProjectInfo = createAsyncThunk('sidebar/getProjectInfo', async (payload, { dispatch, getState }) => {
        try {
            const {data} = await ax.get('/api/v2')
            return data
        } catch (error) {
            console.log(error)
        }
    })


    

const SidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSidebar: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
    extraReducers : (builder) => {
        builder.addCase(getProjectInfo.fulfilled, (state, action) => {
            state.projectInfo = action.payload
        })
    }
});

export const { setSidebar } =SidebarSlice.actions;

export default SidebarSlice.reducer