import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ax from 'src/scss/utils/axios';


const initialState = {
    dtBulkModelAsis: null,
    dtFillingModelAsis: null,
    petroleum : null,
    lpg : null
};



export const getDtBulkModelAsis = createAsyncThunk('models/getDtBulkModelAsis', async (payload, { dispatch, getState }) => {
    try {
        const { data } = await ax.get('/api/v1/dt/bulk/model/asis')
        // Нужно для того чтобы иметь доступ к актуальному названию в breadcrumbs
        // Потому что из redux в роутинг мы никак не вытащим это значение
        localStorage.setItem('bulkModel', data.DigitalTwinBulkTerminal.digitalTwinName)
        return data
    } catch (error) {
        console.log(error)
    }
})

export const getDtFillingModelAsis = createAsyncThunk('models/getDtFillingModelAsis', async (payload, { dispatch, getState }) => {
    try {
        const { data } = await ax.get('/api/v1/dt/filling/model/asis')
        // Нужно для того чтобы иметь доступ к актуальному названию в breadcrumbs
        // Потому что из redux в роутинг мы никак не вытащим это значение
        localStorage.setItem('fillingModel', data.DigitalTwinFillingTerminal.digitalTwinName)
        return data
    } catch (error) {
        console.log(error)
    }
})


export const getPetroleum = createAsyncThunk('models/getPetroleum', async (payload, { dispatch, getState }) => {
    try {
        const { data } = await ax.get('/api/v1/dt/filling/model/asis/petroleum')
        return data
    } catch (error) {
        console.log(error)
    }
})

export const getLpg = createAsyncThunk('models/getLpg', async (payload, { dispatch, getState }) => {
    try {
        const { data } = await ax.get('/api/v1/dt/filling/model/asis/lpg')
        return data
    } catch (error) {
        console.log(error)
    }
})

const ModelsSlice = createSlice({
    name: 'model',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getDtBulkModelAsis.fulfilled, (state, action) => {
            state.dtBulkModelAsis = action.payload
        }),
            builder.addCase(getDtFillingModelAsis.fulfilled, (state, action) => {
                state.dtFillingModelAsis = action.payload
            }),
            builder.addCase(getPetroleum.fulfilled, (state, action) => {
                state.petroleum = action.payload
            }),
            builder.addCase(getLpg.fulfilled, (state, action) => {
                state.lpg = action.payload
            })
    }
});

export const { } = ModelsSlice.actions;

export default ModelsSlice.reducer