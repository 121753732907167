import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ax from 'src/scss/utils/axios';

const initialState = {
    firstFlag: false,
    secondFlag: false,
    progressValue: 0,
    sdpResults: null,
    sdpParameters: null,
    firstLevelSdpResults: null,
    secondLevelSdpResults: null,
    thirdLevelSdpResults: null,
    statusTypes: null,
    tableList: null
};

export const getFirstLevelSsp = createAsyncThunk('ssp/getFirstLevelSsp', async (payload, { dispatch, getState }) => {
    try {
        const { data } = await ax.get('/api/v2/planning/sdp/table', { params: payload })
        return data
    } catch (error) {
        console.log(error)
    }
})

export const getSecondLevelSsp = createAsyncThunk('ssp/getSecondLevelSsp', async (payload, { dispatch, getState }) => {
    try {
        const { data } = await ax.get('/api/v2/planning/sdp/variants/table/filter', { params: payload })
        return data
    } catch (error) {
        console.log(error)
    }
})

export const getThirdLevelSsp = createAsyncThunk('ssp/getThirdLevelSsp', async (payload, { dispatch, getState }) => {
    try {
        const { data } = await ax.get('/api/v2/planning/sdp/variants/filter', { params: payload })
        return data
    } catch (error) {
        console.log(error)
    }
})

export const getSdpResults = createAsyncThunk('ssp/getSdpResults', async (payload, { dispatch, getState }) => {
    try {
        const { data } = await ax.get('/api/v1/planning/sdp/results')
        return data
    } catch (error) {
        console.log(error)
    }
})

export const getSdpParameters = createAsyncThunk('ssp/getSdpParams', async (payload, { dispatch, getState }) => {
    try {
        const { data } = await ax.get('/api/v1/planning/sdp/parameters')
        return data
    } catch (error) {
        console.log(error)
    }
})

export const getStatusTypes = createAsyncThunk('ssp/getStatusTypes', async (payload, { dispatch, getState }) => {
    try {
        const { data } = await ax.get('/api/v2/planning/sdp/status/types')
        return data
    } catch (error) {
        console.log(error)
    }
})

export const getTableListAction = createAsyncThunk('ssp/getTableListAction', async (payload) => {
    try {
        const { data } = await ax.get(`${payload.route}`, { params: payload.params })
        return data
    } catch (error) {
        console.log(error)
    }
})



const SspSlice = createSlice({
    name: 'ssp',
    initialState,
    reducers: {
        setFirstFlag: (state, action) => {
            state.firstFlag = action.payload
        },
        setSecondFlag: (state, action) => {
            state.secondFlag = action.payload
        },
        setProgressValue: (state, action) => {
            state.progressValue = action.payload
        },
        clearSecondLevelSdpResults: (state, action) => {
            state.secondLevelSdpResults = null
        },
        clearThirdLevelSdpResults: (state, action) => {
            state.thirdLevelSdpResults = null
        },
        clearTableListAction: (state, {payload}) => {
            state.tableList = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSdpResults.fulfilled, (state, action) => {
            state.sdpResults = action.payload;
        });
        builder.addCase(getSdpParameters.fulfilled, (state, action) => {
            state.sdpParameters = action.payload;
        });
        builder.addCase(getFirstLevelSsp.fulfilled, (state, action) => {
            state.firstLevelSdpResults = action.payload;
        });
        builder.addCase(getStatusTypes.fulfilled, (state, action) => {
            state.statusTypes = action.payload;
        });
        builder.addCase(getSecondLevelSsp.fulfilled, (state, action) => {
            state.secondLevelSdpResults = action.payload;
        });
        builder.addCase(getThirdLevelSsp.fulfilled, (state, action) => {
            state.thirdLevelSdpResults = action.payload;
        });

        builder.addCase(getTableListAction.fulfilled, (state, action) => {
            state.tableList = action?.payload?.ApiResponsePage;
        });
    }
});

export const { clearTableListAction, setFirstFlag, setSecondFlag, setProgressValue, clearSecondLevelSdpResults, clearThirdLevelSdpResults } = SspSlice.actions;

export default SspSlice.reducer